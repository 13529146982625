/* CSS Document */
a {
  text-decoration: none
}

.btn {
  box-shadow: 0 0 1rem 0.5rem hsla(0,0%,50.2%,.25);
  padding: var(--Spacing-xs, 12px) var(--Spacing-3xl, 32px) !important;
  text-transform: uppercase;
}

input[type=text]  {
  border-color: grey;
  padding: 22px;
  border-left-width: 4px;
}

p a{
    border-bottom-color: #007d61;
    color: #007d61;
    position: relative;
    border-bottom-width: .125rem;
    border-bottom-style: solid;
    text-decoration: none;
    -webkit-transition: all .2s;
    transition: all .2s;
}

p a:hover{
  border-bottom-color: transparent;
  color: #004a39;
  text-decoration: none
}

.alert-dark{
  border-radius: 0;
  background-color: #54585a;
  border: none;
}

.alert-danger {
    color: #ffffff;
    background-color: #a65400;
    border-color: #a65400;
}

.barebone-header img {
    max-width: 15.625rem;
}
.custom-error {
  padding-left:1.5rem;
}
form {
background-image: none !important; 
}
alert {
  border-radius: 0;
}

.cardoptions {
  display: flex;
  padding: var(--Spacing-m, 16px);
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 16px 0px;
  background: var(--Color-Buttons-Primary-Default-Button-colour, #007359);
}
.cardoptions:hover{
  text-decoration: none;
  background: var(--Color-Buttons-Primary-Default-Button-hover, #004234);
}
.cardoptions:focus{
  text-decoration: none;
  background: var(--Color-Buttons-Primary-Default-Button-hover, #004234);
  box-shadow: 0px 0px 4px 2px #F7941D;
}

.btn:hover{
  text-decoration: none;
  background: var(--Color-Buttons-Primary-Default-Button-hover, #004234);
}
.btn:focus{
  text-decoration: none;
  background: var(--Color-Buttons-Primary-Default-Button-hover, #004234);
  box-shadow: 0px 0px 4px 2px #F7941D;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--Color-Buttons-Primary-Default-Button-hover, #007359);
}
.header-text {
  border-bottom-right-radius: 20px;
  border: 1px solid #007359;
  padding: 22px;
  border-left-width: 4px;
}

button:disabled{opacity:.65}

hr {
  border-top: 1.5px solid !important;
}
@media (min-width: 992px) { 
  footer {
    position: absolute;
    bottom: 0;
  }
 }


