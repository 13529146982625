/* CSS Document */
a {
  text-decoration: none
}

.btn {
  box-shadow: 0 0 1rem 0.5rem hsla(0,0%,50.2%,.25);
  padding:  15px 18px;
  text-transform: uppercase;
}

input[type=text]  {
  border-color: grey;
  padding: 22px;
  border-left-width: 4px;
}

p a{
    border-bottom-color: #005B6E !important;
    color: #005B6E !important;
    position: relative;
    border-bottom-width: .125rem;
    border-bottom-style: solid;
    text-decoration: none;
    -webkit-transition: all .2s;
    transition: all .2s;
}

p a:hover{
  border-bottom-color: transparent;
  color: #004a39;
  text-decoration: none
}

.alert-dark{
  background-color: #54585a;
  border: none;
}

.alert-danger {
    color: #ffffff;
    background-color: #a65400;
    border-color: #a65400;
}

.barebone-header img {
    max-width: 15.625rem;
}
.custom-error {
  padding-left:1.5rem;
}
form {
background-image: -webkit-linear-gradient(#fafafa 0%, #f2f2f2);
background-image: -o-linear-gradient(#fafafa 0%, #f2f2f2);
background-image: linear-gradient(#fafafa 0%, #f2f2f2);
}

